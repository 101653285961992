import { useAxios } from '../axiosUtil'
import router from '@/router'

const root = '/v1/lab-industry-category'

/**
 * 산업분류 가져오기
 * TODO: 산학협력 게시글, 등등
 * @return {Array}
 * */
export const getIndustryCategory = async () => {
  const result = await useAxios(`${root}`)
  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}
