<script>
import { getTechHandover } from '@/api/platform/community'
import { insertHandoverTable, updateHandoverTable } from '@/api/admin/ntis/ntisSave'
import { getIndustryCategory } from '@/api/category/industryCategory'

export default {
  name: 'adminBoardResultCreate',
  data() {
    const boardNo = this.$route.params.boardNo // 파라미터 게시글 번호

    return {
      boardNo: boardNo,
      techPageNo: 0,
      labId: 0,
      industryCategoryList: [],
      techStatusIng: true,
      techStatusDone: true,
      techIndustryCategory: ''
    }
  },
  async mounted() {
    // console.log(this.$route.params)
    this.techPageNo = Number(this.$route.params.page) // 산학협력 게시판 페이지 번호
    this.labId = Number(this.$route.params.labNo) // 산학협력 게시판 페이지 번호

    this.industryCategoryList = await getIndustryCategory()
    const boardNo = this.$route.params.boardNo // 파라미터 게시글 번호

    if (!boardNo && boardNo !== 0) {
      const user = this.$store.state.userStore.info
      if (user.groupName === 'lab') {
        this.labNo = user.labNo
        this.$refs.labNoInput.setAttribute('readonly', true)
      }

      return
    }

    this.$refs.labNoInput.setAttribute('readonly', true)

    const detailData = await getTechHandover(boardNo)
    // console.log(detailData)
    this.labNo = detailData.labNo
    this.techDate = this.$getDate(detailData.createdAt)
    this.techStatus = detailData.techStatus
    this.techName = detailData.techName
    this.techNameEn = detailData.techNameEn
    this.techSummary = detailData.techSummary
    this.techSummaryEn = detailData.techSummaryEn
    this.techApplyField = detailData.techApplyField
    this.techApplyFieldEn = detailData.techApplyFieldEn
    this.techTransferType = detailData.techTransferType
    this.techGrowLevel = detailData.techGrowLevel
    this.techTransferResult = detailData.techTransferResult
    this.techTransferResultEn = detailData.techTransferResultEn
    this.techFollowUpSupport = detailData.techFollowUpSupport
    this.techFollowUpSupportEn = detailData.techFollowUpSupportEn
    this.techProgress = detailData.techProgress
    this.techSpecialNote = detailData.techSpecialNote
    this.techSpecialNoteEn = detailData.techSpecialNoteEn
    this.techIndustryCategory = detailData.techIndustryCategory

    if (detailData.techTransferYear !== null) {
      this.techTransferYear = detailData.techTransferYear ? new Date(detailData.techTransferYear).getFullYear() : ''
    }

    // 저장된 정보가 '진행중' => 진행중 SHOW, 완료 HIDE
    if (this.techStatus === '진행중') {
      this.techStatusDone = false
      this.techStatusIng = true
    }
    // 저장된 정보가 '완료' => 진행중 HIDE, 완료 SHOW
    if (this.techStatus === '완료') {
      // 진행중 체크박스 Disabled 처리
      // document.getElementById('techStatusIng').disabled = true
      this.techStatusIng = false
      this.techStatusDone = true
    }
  },
  methods: {
    // 기술 이전 유형 체크박스 하나만 선택하도록 하기
    checkType(e) {
      const type = document.querySelectorAll('.techType')

      for (let i = 0; i < type.length; i++) {
        if (type[i].value !== e.target.value) {
          type[i].setAttribute('checked', true)
        }
      }
    },

    // 진행 상태 체크 박스 이벤트 (해당 상태값에 맞는 그리드 보여주기), 하나만 선택
    checkStatus(e) {
      const status = document.querySelectorAll('.tech_status')

      for (let i = 0; i < status.length; i++) {
        if (status[i].value !== e.target.value) {
          status[i].setAttribute('checked', true)
        }
      }

      // 진행중 상태 => 진행중 SHOW, 완료 HIDE
      if (e.target.value === '진행중') {
        this.techStatusDone = false
        this.techStatusIng = true
        // 완료 상태 => 진행중 HIDE, 완료 SHOW
      } else if (e.target.value === '완료') {
        this.techStatusIng = false
        this.techStatusDone = true
      }
    },

    // 기술 성숙도 단계 하나만 선택
    techGrow(e) {
      const grow = document.querySelectorAll('.techGrow')
      console.log(grow)
      console.log(e.target.value)

      for (let i = 0; i < grow.length; i++) {
        console.log(grow[i])
        if (grow[i].value !== e.target.value) {
          grow[i].setAttribute('checked', false)
        } else {
          grow[i].setAttribute('checked', true)
        }
      }
    },

    // 목록 버튼
    goTechList() {
      this.$router.push({
        path: `/admin/board/result/${this.labId}/${this.techPageNo}`
      })
    },

    // 저장 버튼
    async saveTech() {
      const saveTechData = {
        labNo: this.labNo,
        techDate: this.techDate,
        techStatus: this.techStatus,
        techName: this.techName,
        techNameEn: this.techNameEn,
        techSummary: this.techSummary,
        techSummaryEn: this.techSummaryEn,
        techApplyField: this.techApplyField,
        techApplyFieldEn: this.techApplyFieldEn,
        techTransferType: this.techTransferType,
        techGrowLevel: this.techGrowLevel,
        techTransferYear: new Date(this.techTransferYear?.toString()),
        techTransferResult: this.techTransferResult,
        techTransferResultEn: this.techTransferResultEn,
        techFollowUpSupport: this.techFollowUpSupport,
        techFollowUpSupportEn: this.techFollowUpSupportEn,
        techProgress: this.techProgress,
        techSpecialNote: this.techSpecialNote,
        techSpecialNoteEn: this.techSpecialNoteEn,
        techIndustryCategory: this.techIndustryCategory
      }

      if (!this.labNo) return alert('Error: 연구실 번호를 입력해야 합니다.')
      if (!this.techName) return alert('Error: 기술명을 입력해야 합니다.')
      if (!this.techStatus) return alert('Error: 기업협력 상태를 선택해야 합니다.')
      if (!this.techTransferType) return alert('Error: 기업협력 유형을 선택해야 합니다.')
      if (!this.techGrowLevel) return alert('Error: 기업협력 성숙도 단계를 선택해야 합니다.')

      const result =
        this.boardNo || this.boardNo === 0 //
          ? await updateHandoverTable(saveTechData, this.boardNo)
          : await insertHandoverTable(saveTechData)

      if (result.code) return alert(result.message)

      alert('저장되었습니다.')
      return this.goTechList()
    }
  }
}
</script>

<template>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <!-- 제목 입력 -->
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <h4>연구실협력성과 게시판 등록</h4>
      </nav>

      <div class="adminTable">
        <!-- 내용 입력 -->
        <form action="#" method="post" id="techForm" @submit.prevent="saveTech">
          <!-- 공통 정보 -->
          <table>
            <colgroup>
              <col width="10%" />
              <col width="40%" />
              <col width="40%" />
            </colgroup>
            <tbody>
              <tr>
                <th>연구실 번호</th>
                <td><input type="text" name="labNo" v-model="labNo" ref="labNoInput" placeholder="연구실 번호" /></td>
                <td></td>
              </tr>
              <tr>
                <th>상태</th>
                <td>
                  <input
                    type="radio"
                    id="techStatusIng"
                    v-model="techStatus"
                    name="techStatusIng"
                    value="진행중"
                    class="tech_status"
                    @change="checkStatus"
                  />
                  <label for="techStatusIng">진행중</label>
                </td>
                <td>
                  <input
                    type="radio"
                    id="techStatusDone"
                    v-model="techStatus"
                    name="techStatusDone"
                    value="완료"
                    class="tech_status"
                    @change="checkStatus"
                  />
                  <label for="techStatusDone">완료</label>
                </td>
              </tr>
              <tr>
                <th>기술명</th>
                <td><input type="text" name="techName" v-model="techName" placeholder="기술명" size="70" /></td>
                <td><input type="text" name="techNameEn" v-model="techNameEn" placeholder="technology name" size="70" /></td>
              </tr>
              <tr>
                <th>기술 개요</th>
                <td><textarea type="text" name="techSummary" v-model="techSummary" placeholder="기술 개요"></textarea></td>
                <td><textarea type="text" name="techSummaryEn" v-model="techSummaryEn" placeholder="technology summary"></textarea></td>
              </tr>
              <tr>
                <th>적용 분야</th>
                <td><input type="text" name="techApplyField" v-model="techApplyField" placeholder="적용 분야" size="70" /></td>
                <td>
                  <input type="text" name="techApplyFieldEn" v-model="techApplyFieldEn" placeholder="technology apply field" size="70" />
                </td>
              </tr>
              <tr>
                <th>기술 이전 유형</th>
                <td>
                  <input
                    type="radio"
                    id="techTransferTypeDealing"
                    v-model="techTransferType"
                    name="techTransferType"
                    value="기술매매"
                    class="techType"
                    @change="checkType"
                  />
                  <label for="techTransferTypeDealing">기술매매</label>
                </td>
                <td>
                  <input
                    type="radio"
                    id="techTransferTypeLicense"
                    v-model="techTransferType"
                    name="techTransferType"
                    value="라이센스"
                    class="techType"
                    @change="checkType"
                  />
                  <label for="techTransferTypeLicense">라이센스</label>
                </td>
              </tr>
              <tr>
                <th>기술 성숙도 단계</th>
                <td class="techGrowLevels">
                  <input
                    type="radio"
                    id="techGrowLevel1"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="1"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel1">1단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel2"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="2"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel2">2단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel3"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="3"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel3">3단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel4"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="4"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel4">4단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel5"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="5"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel5">5단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel6"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="6"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel6">6단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel7"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="7"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel7">7단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel8"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="8"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel8">8단계</label>
                  <input
                    type="radio"
                    id="techGrowLevel9"
                    v-model="techGrowLevel"
                    name="techGrowLevel1"
                    value="9"
                    class="techGrow"
                    @change="techGrow"
                  />
                  <label for="techGrowLevel9">9단계</label>
                </td>
                <td></td>
              </tr>
              <tr>
                <th>산업분류</th>
                <td>
                  <select name="techIndustryCategory" v-model="techIndustryCategory">
                    <option v-for="(cate, i) in industryCategoryList" :value="cate.labIndustryCategoryId" :key="i">
                      {{ cate.labIndustryCategoryName }}
                    </option>
                  </select>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />

          <!-- 완료 정보 -->
          <table id="techDone" v-show="techStatusDone">
            <colgroup>
              <col width="10%" />
              <col width="40%" />
              <col width="40%" />
            </colgroup>
            <tbody>
              <tr>
                <th>이전 연도</th>
                <td>
                  <input
                    type="number"
                    min="1900"
                    max="2099"
                    step="1"
                    name="techTransferYear"
                    v-model="techTransferYear"
                    placeholder="이전연도"
                    size="30"
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <th>기술 이전 성과(기업)</th>
                <td>
                  <textarea
                    type="text"
                    name="techTransferResult"
                    v-model="techTransferResult"
                    placeholder="기술 이전 성과(기업)"
                  ></textarea>
                </td>
                <td>
                  <textarea
                    type="text"
                    name="techTransferResultEn"
                    v-model="techTransferResultEn"
                    placeholder="technology transfer result"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <th>후속 지원</th>
                <td><textarea type="text" name="techFollowUpSupport" v-model="techFollowUpSupport" placeholder="후속 지원"></textarea></td>
                <td>
                  <textarea
                    type="text"
                    name="techFollowUpSupportEn"
                    v-model="techFollowUpSupportEn"
                    placeholder="technology follow-up support"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <br />

          <!-- 진행중 정보 -->
          <table id="techIng" v-show="techStatusIng">
            <colgroup>
              <col width="10%" />
              <col width="40%" />
              <col width="40%" />
            </colgroup>
            <tbody>
              <tr>
                <th>진행 상황</th>
                <td><textarea type="text" name="techProgress" v-model="techProgress" placeholder="진행상황"></textarea></td>
                <td></td>
              </tr>
              <tr>
                <th>기술 이전 특이사항</th>
                <td><textarea type="text" name="techSpecialNote" v-model="techSpecialNote" placeholder="기술 이전 특이사항"></textarea></td>
                <td>
                  <textarea
                    type="text"
                    name="techSpecialNoteEn"
                    v-model="techSpecialNoteEn"
                    placeholder="technology special note"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- 버튼 구역 -->
          <div class="btnArea">
            <button type="button" class="e-btn wf__btn btn-primary" @click="goTechList()">목록</button>
            <button type="submit" id="saveTechBtn" class="e-btn wf__btn btn-success ml-2">저장</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.techGrowLevels > input:not(:first-child) {
  margin-left: 20px;
}
#techForm textarea {
  width: 540px;
  height: 100px;
}
.btnArea {
  margin-top: 10px;
}
</style>
